import * as React from 'react';
import { graphql } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import Layout from '../../components/layout/layout';
import SEO from '../../components/seo';
import { PostSummary } from '../../components/post-summary';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import SPOTIFY from '../../images/townhall/spotify.png';
import APPLE from '../../images/townhall/apple.png';
import GOOGLE from '../../images/townhall/google.png';

const Townhall = ({ location, data }) => {
  const open_graph_image = data.Townhall.childImageSharp
    ? data.Townhall.childImageSharp.resize
    : null;

  return (
    <Layout>
      <SEO
        title="GrowSF Town Hall"
        image={open_graph_image}
        description="Listen to folks around the city talk about what they do and how you can get involved"
        pathname={location.pathname}
      />
      <div className="mx-auto max-w-7xl px-4 mt-10 sm:mt-14 sm:px-6">
        {/* <img src={BANNER} alt="GrowSF Town Hall" /> */}
        <div className="text-center">
          <h1 className="font-extrabold text-4xl sm:text-5xl md:text-6xl text-gray-900">
            GrowSF Town Hall
          </h1>
          <h2 className="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
            GrowSF is hosting conversations with folks around San Francisco and in government to
            talk about what they do, help us understand how our city, state, and country operate,
            and how we can get involved.
          </h2>
          <h2 className="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
            Conversations happen live on Twitter Spaces, and recordings are posted to our podcast.
            To hear about upcoming events, subscribe to our email list and follow us on Twitter.
          </h2>
          <h2 className="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
            Subscribe to our podcast using your favorite app:
          </h2>
        </div>
        <div className="flex flex-col space-y-5 sm:space-y-0 mx-auto items-center sm:flex-row sm:space-x-5 justify-center my-10">
          <OutboundLink
            href="https://podcasts.apple.com/us/podcast/grow-sf-town-hall/id1565054417"
            target="_blank"
          >
            <img src={APPLE} className="sm:block" />
          </OutboundLink>

          <OutboundLink href="https://open.spotify.com/show/1DznkqVa8aIqLnGamz6EqL" target="_blank">
            <img src={SPOTIFY} className="sm:block" />
          </OutboundLink>

          <OutboundLink
            href="https://podcasts.google.com/feed/aHR0cHM6Ly9mZWVkcy50cmFuc2lzdG9yLmZtL2dyb3ctc2YtdG93bi1oYWxs"
            target="_blank"
          >
            <img src={GOOGLE} className="sm:block" />
          </OutboundLink>
        </div>
        <h2 className="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
          If you have ideas for topics, speakers, or general feedback,{' '}
          <OutboundLink className="text-brand-blue-3" href="mailto:contact@growsf.org">
            let us know
          </OutboundLink>
          !
        </h2>
      </div>
      <div className="max-w-5xl mx-auto pt-8 pb-16 px-4 sm:py-24 sm:px-6 lg:px-8">
        <ul className="sm:space-y-12 sm:space-y-0 sm:-mt-4 lg:gap-x-8 lg:space-y-0">
          {data.allMdx.nodes.map((node) => (
            <PostSummary
              key={node.id}
              href={`/${node.slug}`}
              image={node.frontmatter.image ? getImage(node.frontmatter.image.childImageSharp) : ''}
              image_alt={node.frontmatter.image_alt}
              title={node.frontmatter.title}
              summary={node.summary}
              link_text="Learn More"
            />
          ))}
        </ul>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    allMdx(
      sort: { fields: frontmatter___date, order: DESC }
      filter: { frontmatter: { post_type: { eq: "townhall" } } }
    ) {
      nodes {
        frontmatter {
          date(formatString: "MMMM D, YYYY")
          title
          image: featured_image {
            childImageSharp {
              gatsbyImageData
            }
          }
          image_alt: featured_image_alt
        }
        id
        slug
        summary: excerpt(pruneLength: 200)
      }
    }
    Townhall: file(relativePath: { eq: "townhall/townhall-opengraph.png" }) {
      childImageSharp {
        resize(width: 1200) {
          src
          height
          width
        }
      }
    }
  }
`;

export default Townhall;
